<template>
<section>
  <ul class="repository-list flex">
    <li v-for="result in results" :key="result.id">
      <a :href="result.url" target="_blank">
        <h2>{{ result.name }}</h2>
        <div class="icon-row flex">
          <div class="icon-group">
            <img src="./assets/icons/star.svg" width="15">
            <span>{{ result.stars }}</span>
          </div>
          <div class="icon-group">
            <img src="./assets/icons/fork.svg" width="15">
            <span>{{ result.forks }}</span>
          </div>
          <div class="icon-group">
            <img src="./assets/icons/eye.svg" width="15">
            <span>{{ result.watchers }}</span>
          </div>
        </div>
        <p>Last commit: {{ result.last_commit }}</p>
        </a>
    </li>
  </ul>
</section>
</template>

<script>
import axios from "axios"

export default {
  data() {
    return {
      // Results data gets assigned to this inside the loadData method
      results: []
    }
  },
  methods: {
    async loadData() {
      try {
      // limiting to 20 for this test as Microsoft has so many repos!
      const url = 'https://api.github.com/orgs/microsoft/repos?page=1&per_page=20';
      const response = await axios.get(url);
      const data = response.data;
      const results = [];
      console.log(data);

      // Loop through the response data so we can assign our key value pairs
      for (const id in data) {
        // get updated_at date
        let date = new Date(data[id].updated_at);
        // format updated_at time
        let time = new Intl.DateTimeFormat("en", {timeStyle: "short"}).format(date);
        results.push({
          id: data[id].id,
          name: data[id].name,
          url: data[id].html_url,
          // ideally would like to format this date to calculate hours/days/months ago
          last_commit: date.toDateString('en-gb') + " " + time,  
          forks: data[id].forks_count, 
          stars: data[id].stargazers_count, 
          watchers: data[id].watchers_count,
          // need to access commits_url potentially - didn't get a chance to see what data comes back from this
          commits_url: data[id].commits_url,
          // need to access contributors_url - didn't get a chance to see what data comes back from this
          contributors_url: data[id].contributors_url,
          // need to access releases_url - didn't get a chance to see what data comes back from this
          releases_url: data[id].releases_url,
          // maybe trees_url - didn't get a chance to see what data comes back from this
          trees_url: data[id].trees_url
          });
        }

        // Assign the results data that we just created to the results array located in data()
        this.results = results;

        console.log(results);
      } catch (err) {
          console.log("Error:", err)
      }
    },
  },
  mounted() {
    this.loadData();
  }
}
</script>

<style>
/* CSS reset */
html, body, h1, h2, h3, ul, li, p, a, div, span {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;
}
html {
  font-family: Arial, Helvetica, sans-serif;
}
section {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1em;
}
h2 {
  word-break: break-all;
}
.repository-list {
  flex-wrap: wrap;
}
.repository-list li {
  list-style-type: none;
  margin: 1em;
  width: calc(50% - 2em);
}
.repository-list a {
  text-decoration: none;
  color: #565656;
  border-radius: 10px;
  border: 1px solid #eee;
  padding: 2em;
  display: block;
}
.flex {
  display: flex;
}
.icon-group {
  padding: 0.5em 1em;
}
.icon-group:first-child {
  padding-left: 0;
}
</style>